import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import cookie from 'cookie'
import isPlainObject from 'lodash/isPlainObject'
import { NextPageContext } from 'next'
import useTranslation from 'next-translate/useTranslation'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { useCookies } from 'react-cookie'

import PageLoader from '~/components/ui/PageLoader'
import { Error } from '~/components/ui/sharedStyledComponents'
import { LUMOSITY_USER_COOKIE, UserHelpCenterUrl } from '~/constants'
import { HandShakeContextType } from '~/events/eventTypes'
import useTrackEvents from '~/events/trackers/useTrackEvents'
import { useEventsInfo } from '~/events/useEventsInfo'
import { useLocalStorageBackedState } from '~/hooks/useLocalStorageBackedState'
import logger from '~/utils/logger'
import { getVisitorInfo } from '~/utils/visitorUtils'

const { publicRuntimeConfig } = getConfig()
const obOrigin = publicRuntimeConfig?.optimizeBox?.origin
const obIframePath = publicRuntimeConfig?.optimizeBox?.iframePath
const usCountryCheck = publicRuntimeConfig?.optimizeBox?.usCheck
const enableOB = publicRuntimeConfig?.optimizeBox?.enable

export type OBServerSideData = {
  LVisitorId?: string
  countryCode?: string
}

export const OBLoader: React.FC<
  OBServerSideData & {
    OBComp: React.ReactNode
    L2Comp: React.ReactNode
  }
> = ({ L2Comp, OBComp, LVisitorId, countryCode }) => {
  const [cookies] = useCookies(['OBUser'])
  const isObUserCookie = cookies?.['OBUser'] === 'true' || cookies?.['OBUser'] === true
  const isUSUser = usCountryCheck && countryCode?.toUpperCase() === 'US'
  const { lang } = useTranslation()
  const { isTreatmentGroup } = getVisitorInfo(LVisitorId, 0.5 /* 50% */)

  logger.debug('L2andOB Component Decider --------', {
    enableOB,
    usCountryCheck,
    isObUserCookie,
    isUSUser,
    countryCode,
    LVisitorId,
    lang,
    isTreatmentGroup,
  })

  // if OB is disabled from env, always load L2
  if (!enableOB) return <>{L2Comp}</>
  // if the user is from US, always load L2
  if (isUSUser) return <>{L2Comp}</>
  // if language is not english, always load L2
  if ((lang || 'en') !== 'en') return <>{L2Comp}</>
  // if we find OBUser cookie, then load OB, even if user doesn't belong to the experimental flow
  // this is to ensure that the user is part of the same flow
  if (isObUserCookie) return <>{OBComp}</>
  // if the user is part of experimental flow, load OB
  if (isTreatmentGroup) return <>{OBComp}</>
  // default load L2
  return <>{L2Comp}</>
}

type SendMessageFunction = (messageData: any) => void
type ReceiveMessageFunction = (e: MessageEvent) => void
interface OBIframeProps {
  onReceiveMessage: (e: MessageEvent, sendMessage: SendMessageFunction) => void
  handShakeContext: HandShakeContextType
}

export const OBIframe: React.FC<OBIframeProps> = ({ onReceiveMessage, handShakeContext }) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const [obProjectConfig] = useLocalStorageBackedState<any>('optimize_box_config', { project_path: '' })
  // if obProjectConfig is not available, use the default path
  // this is used to switch between different projects, mainly for testing purposes
  const obIframeUrl = useMemo(
    () => `${obOrigin}${obProjectConfig?.project_path || obIframePath}?l2_context=${handShakeContext}`,
    [obProjectConfig?.project_path, handShakeContext],
  )

  useLayoutEffect(() => {
    // TODO - In future, this function can be used independently from receiving messages,
    // this should be moved to a separate hook or util
    const sendMessage: SendMessageFunction = (messageData) => {
      iframeRef?.current?.contentWindow?.postMessage?.(messageData, obOrigin)
      logger.debug('L2-Send-to-OB', messageData)
    }

    const receiveMessage: ReceiveMessageFunction = (e) => {
      if (e.origin !== obOrigin) return
      logger.debug('L2-Receive-from-OB', e.data)
      onReceiveMessage(e, sendMessage)
    }

    window.addEventListener('message', receiveMessage)
    return () => {
      window.removeEventListener('message', receiveMessage)
    }
  }, [onReceiveMessage])

  const onIframeError = useCallback(
    (e) => {
      setLoading(false)
      logger.error('L2-OBIframe::iframeError', e, obIframeUrl)
      setError(e)
      // TODO - Fallback to L2 page
    },
    [obIframeUrl],
  )

  const onIframeLoad = useCallback(() => {
    setLoading(false)
  }, [])

  return (
    <>
      {loading ? <PageLoader /> : null}
      {error ? <Error /> : null}
      <iframe
        ref={iframeRef}
        title='ob'
        src={obIframeUrl}
        className='w-full h-[100dvh] overflow-auto'
        onError={onIframeError}
        onLoad={onIframeLoad}
        data-hj-allow-iframe='true'
      ></iframe>
    </>
  )
}

type OBCommonFrameProps = {
  handShakeContext: HandShakeContextType
}
export const OBCommonFrame: React.FC<OBCommonFrameProps> = ({ handShakeContext }) => {
  const { getEventInfo } = useEventsInfo()
  const router = useRouter()

  // because OB can send message saying OPEN_<PageName>_PAGE, L2 code will route the user to the PageName
  // because router is involved, it takes some time for the redirection to happen,
  // during which user might feel stuck if there is a delay in response, thus we will open a loader whenever we are about to redirect
  // RISK - we need to carefully check if we need to make loader off, for any error cases
  const [loading, setLoading] = useState(false)
  const [{ [LUMOSITY_USER_COOKIE]: lumosityUserCookie }] = useCookies([LUMOSITY_USER_COOKIE])
  const { trackEvent } = useTrackEvents()

  const onReceiveMessage = useCallback(
    (e: MessageEvent, sendMessage) => {
      const Actions = {
        OB_READY: () => {
          sendMessage?.({
            action: 'L2_INIT',
            payload: {
              context: handShakeContext,
              // send partial data, OB should be populating/overriding dynamic info
              partialEventInfo: getEventInfo(),
              lumosityUserCookie: lumosityUserCookie,
            },
          })
        },
        OPEN_REGISTRATION_PAGE: () => {
          setLoading(true)
          router.push('/signup')
        },
        OPEN_LOGIN_PAGE: () => {
          setLoading(true)
          router.push('/login')
        },
        OPEN_FIT_TEST_PAGE: () => {
          setLoading(true)
          // payload : {
          //   fitVariant: 'result_post_fit'
          //   gameSlugs : ['color-match', 'memory-matrix']
          //   isOBDone: false
          // }
          if (e?.data?.payload && isPlainObject(e?.data?.payload)) {
            localStorage.setItem('ob_action__open_fit_test_page', JSON.stringify(e.data.payload))
          }
          router.push('/fit-test')
        },
        OPEN_PRIVACY_POLICY: () => {
          setLoading(true)
          router.push('/privacy-policy')
        },
        OPEN_TOS: () => {
          setLoading(true)
          router.push('/terms-of-service')
        },
        OPEN_CALIFORNIA_PRIVACY: () => {
          setLoading(true)
          router.push('/privacy-policy#what-information-we-collect')
        },
        OPEN_HELP_CENTER: () => {
          setLoading(true)
          window.open(UserHelpCenterUrl)
        },
        OPEN_SETTINGS: () => {
          setLoading(true)
          router.push('/settings')
        },
        OPEN_LOGOUT_PAGE: () => {
          router.push('/logout')
        },
        OPEN_PLAN_SELECTION_PAGE: () => {
          setLoading(true)
          router.push('/subscribe')
        },
      } as const

      const action: string = e.data?.action || ''
      Actions[action as keyof typeof Actions]?.()
    },
    [getEventInfo, handShakeContext, lumosityUserCookie, router],
  )

  useEffect(() => {
    trackEvent('ob_iframe_launch', { context: handShakeContext })
  }, [trackEvent, handShakeContext])

  return (
    <>
      {loading ? <PageLoader /> : null}
      <OBIframe onReceiveMessage={onReceiveMessage} handShakeContext={handShakeContext} />
    </>
  )
}

// this runs on the server side
export const getOBServerSideProps = (req: NextPageContext['req']): OBServerSideData => {
  const cookieValue = req?.headers?.cookie || ''
  const cookies = cookie.parse(cookieValue)
  // L-VisitorID is http-only, thus getting it from the server side, and passing it to the client
  const LVisitorId = cookies['L-VisitorID'] || ''

  // We already have country api, We are using the same logic to get the country
  // AWS Cloudfront has a country header for us with SST!
  // https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/adding-cloudfront-headers.html#cloudfront-headers-viewer-location
  const countryCode: string = (req?.headers?.['cloudfront-viewer-country'] as string) || 'Unknown'

  return { LVisitorId, countryCode }
}
