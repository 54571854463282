import { useCallback, useEffect, useState } from 'react'

export function useLocalStorageBackedState<T>(
  key: string,
  defaultValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [state, setStateBase] = useState<T>(() => {
    // Return the default value during SSR
    if (typeof window === 'undefined') {
      return defaultValue
    }
    // Attempt to retrieve the value from localStorage during CSR
    const storedValue = localStorage.getItem(key)
    if (storedValue) {
      return JSON.parse(storedValue)
    } else {
      localStorage.setItem(key, JSON.stringify(defaultValue))
      return defaultValue
    }
  })

  useEffect(() => {
    const storageHandler = (e: StorageEvent) => {
      // note - this function may get called any point in time, based on storage change
      // if the value is changed for this key, then set latest store value
      if (e.key === key) {
        const latestStoreValue = localStorage.getItem(key)
        if (latestStoreValue) {
          setStateBase(JSON.parse(latestStoreValue))
        } else {
          setStateBase(defaultValue)
        }
      }
    }
    window.addEventListener('storage', storageHandler)
    return () => window.removeEventListener('storage', storageHandler)
  }, [defaultValue, key])

  const setState = useCallback(
    (value: React.SetStateAction<T>) => {
      const newState = value instanceof Function ? value(state) : value
      setStateBase(newState)
      localStorage.setItem(key, JSON.stringify(newState))
    },
    [key, state],
  )

  return [state, setState]
}
