// This is used to find the visitor details.
// when user hits lumosity.com, Fastly sets the visitor cookie, having visitor number assigned to it
// using the visitor number we can decide to do A/B testing.
// Example: we can split 50% user to optimize box and 50% to L2, for landing page or onboarding page.
export const getVisitorInfo = (
  cookieValue: string = '',
  L2_AB_Percent: number /** example 0.9 -> 90% should go to L2, 10% to OB */,
): IVisitorInfoResponse => {
  // cookie L-VisitorID tracks the visitor ID for fresh user who do not have any L2 or flagship Cookie
  // L-VisitorID: C.62/10.356004.IN.IN-KA.0194a645-596e-7bbb-9046-1b4e37cfd546  ----- Max 100
  // (C stands for control group, 62 is the visitor number)
  // L-VisitorID: T.9/10.356004.IN.IN-KA.0194a645-596e-7bbb-9046-1b4e37cfd546  ----- Max 10
  // (T stands for treatment group, 9 is the visitor number)

  // only process treatment group
  const match = cookieValue.match(/^T\.(\d+)\/(\d+)\..*/)
  if (!match) return { isTreatmentGroup: false }

  const visitorNumber: number = Number(match[1] || '-1')
  const maxTreatmentNumber = Number(match[2] || '1')
  const treatmentNumber = Math.round(maxTreatmentNumber * L2_AB_Percent)

  // Technicalities
  // [0 to 99] -> visitor Ids
  // [0 to maxTreatmentNumber] will be L2 and [maxTreatmentNumber to 99] will be flagship
  // the pattern is T.<visitorId>/<maxTreatmentNumber>.<metroCode>.<country>.<region>.<uuid>
  // let treatmentNumber is between 0 to maxTreatmentNumber, thus [0 to treatmentNumber] should be L2 and [treatmentNumber to maxTreatmentNumber] should be Optimize box,
  // treatmentNumber should be 50% of maxTreatmentNumber, this 50% is the A/B test percentage
  const isTreatmentGroup = (() => {
    if (treatmentNumber < 0) return false
    if (maxTreatmentNumber < visitorNumber) return false // invalid cookie T.30/10.xxxxx.IN.IN-KA.xxxxx
    return visitorNumber >= treatmentNumber // [0 to treatmentNumber] is L2 and [treatmentNumber to maxTreatmentNumber] is OB
  })()

  const responseData: IVisitorInfoResponse = { isTreatmentGroup }

  return responseData
}
