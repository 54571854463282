import { useContext, useEffect, useRef } from 'react'

import { EventsContext } from '~/events/EventsContext'
import { SpecificEventType } from '~/events/trackers/useTrackEvents'
import { isShallowEqual } from '~/events/utils/isShallowEqual'

type TrackScreenViewData = SpecificEventType['screen_view']

interface IDataRef extends Partial<TrackScreenViewData> {
  currentScreen?: string
}

interface Config {
  enabled?: boolean
}
/**
 * pageViewData is master set of properties across vendors, this hook will decide, which client get what properties
 * This hook is also responsible to set currentScreen and other hooks uses the same data
 * config.enabled = true will send the event, make it false, if you are still preparing the data to be sent
 * */
const useTrackScreenView = (pageViewData: TrackScreenViewData | null, config?: Config) => {
  const enabled = config?.enabled === false ? false : true // making sure it true, unless someone explicitly passes it false
  const {
    clients: { lumosEventApi },
    currentScreen,
    setCurrentScreen,
  } = useContext(EventsContext)

  // ref is used to store old information, comparing it with new information, to make decision about sending the event
  const dataRef = useRef<IDataRef>({})
  const oldData = dataRef.current

  useEffect(() => {
    if (!enabled) return
    if (!pageViewData?.screen_name) return

    // to prevent sending event 2 time.
    // 1. event1: { newScreen : "ABC", oldScreen:""}
    // 2. event2: { newScreen : "ABC", oldScreen:"ABC"} <- because this hook is responsible to set currentScreen
    // to prevent it, currentScreen !== pageViewData.name, & isShallowEqual will take care of that
    const newData: IDataRef = { ...pageViewData, currentScreen: pageViewData.screen_name }

    // if there is a change in data, send the event,
    if (!isShallowEqual(oldData, newData)) {
      dataRef.current = newData

      // This hook will set data.name as the currentScreen in next rerender, thus here currentScreen is the previous_screen.
      lumosEventApi.track('screen_view', { ...pageViewData, previous_screen: currentScreen })

      setCurrentScreen(pageViewData.screen_name)
    }
  }, [lumosEventApi, currentScreen, setCurrentScreen, oldData, pageViewData, enabled])
}

export default useTrackScreenView
